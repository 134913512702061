<template>
  <div class="page-wrapper">
    <section class="hero modules">
      <div class="left" data-inview="fadeInUp" data-delay="200">
        <p class="text">Modules gestion des opérations</p>
        <h1 class="title">Centralisez votre gestion sur une même plateforme : des communications aux ressources humaines</h1>
      </div>
      <div class="right">
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
        <img src="@/assets/img/hero-mask.svg" class="mask" alt="Mask" />
        <div class="img gestion" data-inview="revealRight" data-delay="200"></div>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Babillard</h2>
        <p class="text">
          Le babillard permet au gestionnaire ou à tout autre utilisateur autorisé de poster un message à tous ou à un groupe restreint ou à une seule
          personne selon la situation. Il permet à celui qui a émis le message de voir qui a lu et accusé réception du message. De plus, il est possible
          que ces messages soient transmis par courriel ou SMS afin de toujours rejoindre tous vos employés. Fini les messages écrits sur un petit bout de
          papier !
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Ressources humaines</h2>
        <p class="text">
          Véritable dossier employé informatisé et sécurisé. Les gestionnaires ont un accès complet aux différents onglets: rencontres et/ou évaluations
          périodiques, salaires et avantages sociaux, formations, absence et/ou maladie et rencontres disciplinaires. Pour chaque onglet, il est possible
          d’y attacher un ou plusieurs documents. En un clin d’œil, voyez le dossier complet de vos employés.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Agenda et tâches</h2>
        <p class="text">Enfin, un agenda adapté à la réalité des professionnels en pharmacie et totalement modulable :</p>
        <ul>
          <li class="text">Possibilité de créer plusieurs catégories de tâches</li>
          <li class="text">Permet de planifier les rendez-vous et les tâches à accomplir</li>
          <li class="text">Possibilité de créer plusieurs agendas pour les différents membres de votre organisation</li>
          <li class="text">Possibilité d’ajouter un suivi suite à une note et de visualiser la note</li>
          <li class="text">Notification possible des tâches et rendez-vous non complétés par courriel ou SMS au gestionnaire concerné</li>
          <li class="text">Planification des tâches récurrentes facilitées</li>
        </ul>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Alertes</h2>
        <p class="text">
          Permet de notifier de façon récurrente ou ponctuelle tout message à vos employés ou vos patients via SMS, courriel ou même à l’écran. Il est
          possible aussi de joindre une alerte à une tâche existante afin de notifier son rappel, son acquittement ou non-acquittement
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Bibliothèque</h2>
        <p class="text">
          Accessible à tous, la bibliothèque permet à l’utilisateur de créer sa propre banque de liens web ou de documents tels que des brochures, des
          feuillets d’information ou autres documents qu’il juge important de conserver, permettant ainsi une consultation rapide et efficace à partir du
          poste de travail. Fini les cartables et les notes sur papier brouillon ! Pour chaque document inscrit, il est possible d’y rattacher des
          mots-clés, une date d’émission ainsi qu’une date de validation afin de permettre une réévaluation périodique des documents inscrits. Il est aussi
          possible de créer différentes catégories de documents dans cette bibliothèque.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Registre d’erreur</h2>
        <p class="text">
          Un site, un registre, accessible par tous les postes. En quelques clics, décrivez l’erreur de façon détaillée. Les statistiques se génèrent
          automatiquement. Un outil simple permettant un processus d’amélioration continue. Il est possible de noter les incidents et les accidents. Pour
          chaque accident, il est aussi possible de produire un rapport détaillé imprimable.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Tableau de bord</h2>
        <p class="text">
          Permet rapidement de consulter l’ensemble tes tâches à venir ou en retard. Il est aussi possible de consulter les tâches qui vous sont
          spécifiquement adressées.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Modules gestion des opérations'
      },
      meta: [
        {
          name: 'description',
          content: 'Centralisez votre gestion sur une même plateforme, des communications aux ressources humaines',
          id: 'desc'
        },
        { name: 'application-name', content: 'Modules gestion des opérations' },
        { name: 'twitter:title', content: 'Centralisez votre gestion sur une même plateforme, des communications aux ressources humaines' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Centralisez votre gestion sur une même plateforme, des communications aux ressources humaines'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Modules gestion des opérations' },
        { property: 'og:site_name', content: 'Modules gestion des opérations' },
        {
          property: 'og:description',
          content: 'Centralisez votre gestion sur une même plateforme, des communications aux ressources humaines'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
