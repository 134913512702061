<template>
  <div class="page-wrapper">
    <section class="hero-about">
      <div class="data" data-inview="fadeInUp" data-delay="200">
        <p class="text">À propos</p>
        <h1 class="title">
          Des <span>outils</span> pour les professionnels de la santé <span>développés</span> par des <span>professionnels</span> de la santé !
        </h1>
      </div>
    </section>

    <!-- <section class="about-img">
      <img src="@/assets/img/about-img.jpg" alt="À propos Rx ToolBox">
    </section> -->

    <section class="split-img-text left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <img src="@/assets/img/quote.svg" class="quote" alt="Quote icon" />
        <h2 class="title medium">Né d'un besoin, développé pour votre réalité !</h2>
        <p class="text italic">
          Le site RxToolbox a été créé pour améliorer la communication interprofessionnelle, ce qui facilite le difficile processus d'intégration des
          connaissances cliniques à son application pratique. Les outils proposés facilitent la documentation, la transmission et le suivi de
          l'intervention.
        </p>
        <p class="text name">Jean-Louis Auger</p>
        <p class="text">Pharmacien et fondateur</p>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <img src="@/assets/img/jean-louis-auger-big-2.jpg" alt="Jean-Louis Auger" />
      </div>
    </section>

    <section class="story-wrap">
      <div class="top">
        <h2 class="title medium" data-inview="fadeInUp" data-delay="200">Biographie de <br /><span>Jean-Louis Auger</span></h2>
        <p class="text">
          Jean-Louis Auger évolue dans le domaine de la santé depuis plus de 25 ans en tant que pharmacien-propriétaire. Au fil de sa carrière, il a
          parallèlement œuvré en pharmacie hospitalière lui permettant ainsi d’acquérir une expertise reconnue en GMF et en milieu gériatrique tout en
          cernant la réalité, les enjeux et les besoins évolutifs de ces milieux.<br /><br />Jean-Louis Auger sait reconnaître l’importance d’une relation
          étroite et complémentaire entre les pharmaciens salariés, le personnel traitant des RPA, les médecins et autres professionnels de la santé.
          Témoin privilégié de la réalité et des besoins modernes liant les principaux acteurs de l’industrie du secteur de la santé, le pharmacien
          d’expérience a su innover et mettre sur pied une plateforme technologique opérationnelle visant à assurer une pratique des soins plus simple,
          plus efficace, et surtout, reconnue pour sa fiabilité et son haut niveau de sécurité: RX TOOLBOX.<br /><br />Cette plateforme technologique
          intégrée permet maintenant aux pharmaciens de consacrer plus de temps à leurs patients en maximisant l’efficacité de leurs tâches quotidiennes.
          L’Ordre des Pharmaciens du Québec a souligné le caractère innovant et transformationnel de RX Toolbox en lui décernant le Prix innovation 2020.
        </p>
      </div>
      <!-- <div class="bottom">
        <div class="year-box" data-inview="fadeInUp" data-delay="200">
          <p class="text year">2001</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
        <div class="year-box" data-inview="fadeInUp" data-delay="300">
          <p class="text year">2004</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
        <div class="year-box" data-inview="fadeInUp" data-delay="400">
          <p class="text year">2008</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
        <div class="year-box" data-inview="fadeInUp" data-delay="500">
          <p class="text year">2010</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
        <div class="year-box">
          <p class="text year">2015</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
        <div class="year-box">
          <p class="text year">2022</p>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
        </div>
      </div> -->
    </section>

    <section class="prix-opq" data-inview="fadeInUp" data-delay="200">
      <img src="@/assets/img/opq-logo.png" class="opq" alt="Logo Ordre des pharmaciens du Québec" />
      <h3 class="title">RX Tool Box reçoit le <span>prix Innovation 2020</span> par l’Ordre des pharmaciens du Québec</h3>
      <a href="https://www.youtube.com/watch?v=A92XN48XxHI" target="_blank" class="round glightbox">
        <svg viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.5 0C20.836 0 0 20.836 0 46.5C0 72.164 20.836 93 46.5 93C72.164 93 93 72.164 93 46.5C93 20.836 72.164 0 46.5 0ZM38.1068 26.8455C37.1574 26.2294 35.9407 26.1868 34.9448 26.7293C33.945 27.2718 33.325 28.3142 33.325 29.4495V63.5495C33.325 64.6849 33.945 65.7272 34.9448 66.2698C35.9407 66.8123 37.1574 66.7697 38.1068 66.1535L64.4563 49.103C65.3398 48.5295 65.8746 47.5492 65.8746 46.499C65.8746 45.4489 65.3398 44.4685 64.4563 43.895L38.1068 26.8455Z"
            fill="#0B8A81"
          />
        </svg>
      </a>
    </section>

    <section class="split-img-text right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <img src="@/assets/img/about-team.jpg" alt="Rx ToolBox Équipe" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Rencontrez notre équipe</h2>
        <p class="text">
          Composée de spécialistes dans les domaines de la santé, de la programmation et de la diffusion web, notre équipe comprend l'importance d'adapter
          le logiciel aux besoins de l’usager. Une synergie parfaite permettant de préserver l'intuition clinique de l'utilisateur. Nous sommes à l'écoute
          de vos besoins!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'À propos'
      },
      meta: [
        {
          name: 'description',
          content: 'Des outils pour les professionnels de la santé développés par des professionnels de la santé !',
          id: 'desc'
        },
        { name: 'application-name', content: 'À propos' },
        { name: 'twitter:title', content: 'Des outils pour les professionnels de la santé développés par des professionnels de la santé !' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Des outils pour les professionnels de la santé développés par des professionnels de la santé !'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'À propos' },
        { property: 'og:site_name', content: 'À propos' },
        {
          property: 'og:description',
          content: 'Des outils pour les professionnels de la santé développés par des professionnels de la santé !'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
