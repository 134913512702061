<template>
  <div class="page-wrapper">
    <section class="hero-contact">
      <div v-if="!isSuccess" class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="title"><span>Demande</span> d'informations</h1>
        <p class="text">Contactez-nous avec toutes vos questions et il nous fera plaisir de vous répondre dans les plus brefs délais.</p>
        <a href="mailto:info@rxtoolbox.com" class="mail-link">
          <img src="@/assets/img/icon-mail.svg" class="mail" alt="Mail Icon" />
          <p class="text">info@rxtoolbox.com</p>
        </a>
      </div>

      <div v-else class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="title"><span>Merci!</span></h1>
        <p class="text">Votre demande a bien été reçue. Nous vous contacterons dans les plus brefs délais.</p>
      </div>
    </section>

    <section v-if="!isSuccess" class="form-wrap" data-inview="fadeInUp" data-delay="400">
      <div class="form">
        <div class="lines">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>

        <form action="/success" name="soumissions" method="post" enctype="multipart/form-data" @submit="sendForm">
          <input v-model="form.nom" type="text" placeholder="Prénom et nom" required />
          <input v-model="form.courriel" type="mail" placeholder="Courriel" required />
          <input v-model="form.telephone" type="text" placeholder="Téléphone" />
          <select v-model="form.profession">
            <option value="">Profession</option>
            <option value="Pharmaciens salariés">Pharmaciens salariés</option>
            <option value="Personnel des RPA">Personnel des RPA</option>
            <option value="Pharmaciens propriétaires">Pharmaciens propriétaires</option>
          </select>
          <input v-model="form.etablissement" type="text" placeholder="Nom de votre établissement" />
          <select v-model="form.joindre">
            <option value="">Meilleur moment pour vous rejoindre</option>
            <option value="Lundi AM">Lundi AM</option>
            <option value="Lundi PM">Lundi PM</option>
            <option value="Mardi AM">Mardi AM</option>
            <option value="Mardi PM">Mardi PM</option>
            <option value="Mercredi AM">Mercredi AM</option>
            <option value="Mercredi PM">Mercredi PM</option>
            <option value="Jeudi AM">Jeudi AM</option>
            <option value="Jeudi PM">Jeudi PM</option>
            <option value="Vendredi AM">Vendredi AM</option>
            <option value="Vendredi PM">Vendredi PM</option>
          </select>
          <input type="submit" value="Envoyer" />
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createContact } from '@/misc/firebase-init'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Contact',
      },
      meta: [
        {
          name: 'description',
          content: 'Contactez-nous avec toutes vos questions et il nous fera plaisir de vous répondre dans les plus brefs délais.',
          id: 'desc',
        },
        { name: 'application-name', content: 'Contact' },
        {
          name: 'twitter:title',
          content: 'Contactez-nous avec toutes vos questions et il nous fera plaisir de vous répondre dans les plus brefs délais.',
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Contactez-nous avec toutes vos questions et il nous fera plaisir de vous répondre dans les plus brefs délais.',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Contact' },
        { property: 'og:site_name', content: 'Contact' },
        {
          property: 'og:description',
          content: 'Contactez-nous avec toutes vos questions et il nous fera plaisir de vous répondre dans les plus brefs délais.',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg',
        },
      ],
    }
  },
  data() {
    return {
      form: {
        nom: '',
        courriel: '',
        telephone: '',
        profession: '',
        etablissement: '',
        joindre: '',
      },
      isSending: false,
      error: '',
      isSuccess: false,
    }
  },
  watch: {
    $route: {
      handler($route) {
        // If route path is success
        if ($route) {
          if ($route.path.includes('success') || window.location.href.includes('success')) {
            this.isSuccess = true
          }
        }
      },
      immediate: true,
    },
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    async sendForm(event) {
      event.preventDefault()

      if (this.isSending) {
        return
      }

      this.isSending = true
      const contact = this.form

      contact.createdAt = new Date().toISOString()

      try {
        await createContact(contact)
        this.form = {}
        window.location.href = '/success'
      } catch (e) {
        this.error = e
        this.isSending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
