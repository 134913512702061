<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'


export default {
  components: { NavBar, FooterBar }
}
</script>

<style lang="scss">

  @import '@/theme/variables.scss';
  @import '@/theme/typos.scss';
  @import '@/theme/buttons.scss';
  @import '@/theme/home.scss';
  @import '@/theme/user-details.scss';
  @import '@/theme/contact.scss';
  @import '@/theme/apropos.scss';
  @import '@/theme/tarifs.scss';
  @import '@/theme/glightbox.scss';
  @import '@/theme/animations-layer.scss';

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
  }

  .body-padding-bottom {
    padding-bottom: 200px;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }
</style>
