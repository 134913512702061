<template>
  <div class="page-wrapper rpa">
    <section class="hero-user">
      <div class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="text">Personnel des RPA</h1>
        <h2 class="title">Facilitez l'ensemble de vos <span>opérations</span> quotidiennes et vos <span>communications</span></h2>
        <a v-scroll-to:-120 href="#features" class="cta">
          <p class="text">Découvrez les fonctionnalités</p>
        </a>
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
      </div>
    </section>

    <section class="intro-arguments">
      <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
        Plus d’<span class="color">efficacité</span>, plus de <span class="color">sécurité</span>,<br />moins de paperasse!
      </h2>
      <div class="arguments">
        <div class="argument" data-inview="fadeInUp" data-delay="200">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Activez vos commandes facilement en limitant les commandes téléphoniques et en évitant la rédaction de commandes manuscrites</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="300">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Planifiez les visites médicales efficacement tout en laissant un rapport exemplaire à vos collègues</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="400">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">S’implante facilement et ne requiert aucun matériel informatique supplémentaire</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="500">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Créez et personnalisez vos FADM selon vos propres exigences et façons de faire</p>
        </div>
      </div>
    </section>

    <section id="features" class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Système de commandes</h2>
          <h3 class="title medium">Rassemblez toutes vos données sous une même plateforme</h3>
          <p class="text">
            Chaque patient a son propre profil en terme d’ordonnance de médicaments. En quelques clics, procédez aux commandes et elles seront
            automatiquement dirigées vers leurs pharmacies respectives. Chaque commande est ainsi enregistrée et conservée pour référence ou consultation
            ultérieure.
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta orange">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-residence" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-rpa-1.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/rpa-feature-1.svg" alt="PharmaForms" />
          <p class="text">Envoyez les commandes électroniquement à la pharmacie si celle-ci est également connectée à RX Toolbox.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/rpa-feature-2.svg" alt="PharmaForms" />
          <p class="text">
            Transmettez les demandes de consultation via Rx Toolbox en Format PDF, par courriel ou télécopieur via l'application si la pharmacie n’est pas
            connectée.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/rpa-feature-3.svg" alt="PharmaForms" />
          <p class="text">Créez des commandes dans un format unifié pour faciliter leur traitement du côté des pharmacies.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/rpa-feature-4.svg" alt="PharmaForms" />
          <p class="text">Passez vos commandes rapidement et favorisez leur compréhension grâce aux options clairement listées.</p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">PharmaConsult</h2>
          <h3 class="title medium">Effectuez des demandes de consultation proactive aux professionnels de la santé</h3>
          <p class="text">
            Il est vrai qu’une consultation médicale peut être parfois difficile à obtenir et maintenant plusieurs professionnels de la santé peuvent aider
            vos patients selon les circonstances. À l’aide des questionnaires standardisés, transmettez une information précieuse et juste.
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta orange">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-residence" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideRight" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-rpa-2.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/rpa-feature-5.svg" alt="PharmaForms" />
          <p class="text">Envoyez les commandes électroniquement à la pharmacie via la plateforme si celle-ci est également connectée à RX Toolbox.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/rpa-feature-6.svg" alt="PharmaForms" />
          <p class="text">Transmettez les listes de commandes en format PDF par courriel ou fax si la pharmacie n’est pas connectée</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/rpa-feature-7.svg" alt="PharmaForms" />
          <p class="text">Favorisez une meilleure compréhension de la situation par le personnel soignant.</p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Visite médicale</h2>
          <h3 class="title medium">Préparer votre visite médicale</h3>
          <p class="text">Donner un résumé clair et précis aux médecins pour chaque patient concerné</p>
          <div class="cta-wrap">
            <a href="/contact" class="cta orange">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-residence" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideRight" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-rpa-3.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/rpa-feature-8.svg" alt="PharmaForms" />
          <p class="text">Pour chaque visite médicale, ajouter les patients en décrivant le problème ou le besoin de ces derniers.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/rpa-feature-9.svg" alt="PharmaForms" />
          <p class="text">Une fois les patients inscrits à la visite, imprimez ou transmettez cette dernière aux professionnels dédiés.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/rpa-feature-10.svg" alt="PharmaForms" />
          <p class="text">Chaque résumé de visite est conservé dans le dossier du patient pour consultation ultérieure ou suivi si requis.</p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Dossier patients et signes vitaux</h2>
          <h3 class="title medium">Créez des dossiers personnalisés</h3>
          <p class="text">
            Pour chaque patient, un dossier permettant l’inscription de signes vitaux, de notes pertinentes, des commandes, des résumés de visite médicale.
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta orange">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-residence" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideRight" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-rpa-4.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/rpa-feature-11.svg" alt="PharmaForms" />
          <p class="text">Un dossier facile à consulter donnant des informations précises et complètes</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/rpa-feature-12.svg" alt="PharmaForms" />
          <p class="text">Possibilité de créer un FADM adapté aux besoins spécifiques de la résidence</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/rpa-feature-7.svg" alt="PharmaForms" />
          <p class="text">Enregistrement des signes vitaux et transmissions aux professionnels concernés</p>
        </div>
      </div>
    </section>

    <!-- <section class="video-wrap" data-inview="fadeInUp" data-delay="200">
      <div class="browser-top">
        <div class="round"></div>
        <div class="round"></div>
        <div class="round"></div>
      </div>
      <a href="https://www.youtube.com/watch?v=W1k8wqZUSOE" class="video glightbox">
        <div class="data">
          <h3 class="title small">L'outil par excellence pour<br>le personnel traitant des RPA</h3>
          <img src="@/assets/img/play.svg" class="play" alt="Play icon">
        </div>
        <div class="overlay"></div>
        <img src="@/assets/img/video-bg.jpg" class="video-bg" alt="Découvrez RX Tool Box pour les Pharmaciens salariés">
      </a>
    </section> -->

    <section class="testimonials-wrap">
      <div class="testimonial-wrap orange" data-inview="fadeInUp" data-delay="200">
        <div class="line"></div>
        <p class="title">« Enfin, je peux créer mes propres FADM et les imprimer <span>au moment opportun</span> à chaque mois »</p>
        <div class="name">
          <p class="title">Catherine,</p>
          <p class="text">RPA</p>
        </div>
        <img src="@/assets/img/testimonials-rpa.svg" class="forme" alt="forme" />
      </div>
      <div class="testimonial-wrap orange" data-inview="fadeInUp" data-delay="300">
        <div class="line"></div>
        <p class="title">
          « Lorsqu’un de mes patients souffre d’un problème, il est maintenant <span>facile de décrire la situation</span> et de transmettre les
          informations pertinentes »
        </p>
        <div class="name">
          <p class="title">Guillaume,</p>
          <p class="text">RPA</p>
        </div>
        <img src="@/assets/img/testimonials-rpa.svg" class="forme" alt="forme" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Personnel des RPA'
      },
      meta: [
        {
          name: 'description',
          content: "Facilitez l'ensemble de vos opérations quotidiennes et vos communications",
          id: 'desc'
        },
        { name: 'application-name', content: 'Personnel des RPA' },
        { name: 'twitter:title', content: "Facilitez l'ensemble de vos opérations quotidiennes et vos communications" },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: "Facilitez l'ensemble de vos opérations quotidiennes et vos communications"
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Personnel des RPA' },
        { property: 'og:site_name', content: 'Personnel des RPA' },
        {
          property: 'og:description',
          content: "Facilitez l'ensemble de vos opérations quotidiennes et vos communications"
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
