<template>
  <div class="page-wrapper proprietaires">
    <section class="hero-user">
      <div class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="text">Pharmaciens propriétaires</h1>
        <h2 class="title"><span>Simplifiez et centralisez</span> la gestion de vos opérations en pharmacie</h2>
        <a v-scroll-to:-120 href="#features" class="cta">
          <p class="text">Découvrez les fonctionnalités</p>
        </a>
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
      </div>
    </section>

    <section class="intro-arguments">
      <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
        <span class="color">Personnalisez</span> la plateforme selon vos besoins et ceux de votre équipe
      </h2>
      <div class="arguments">
        <div class="argument" data-inview="fadeInUp" data-delay="200">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Une seule plateforme infonuagique adaptée selon la compétence technologique des patients et employés</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="300">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">
            Modules permettant une meilleure gestion de vos opérations, une simplification de vos activités cliniques et une meilleure communication avec
            vos résidences.
          </p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="400">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Favorise l’implication clinique des pharmaciens à tout niveau</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="500">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Simplifie le quotidien de tous les employés de la pharmacie</p>
        </div>
      </div>
    </section>

    <section id="features" class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Module de gestion</h2>
          <h3 class="title medium">Une gestion optimale de votre pharmacie, à votre façon</h3>
          <p class="text">Centralisez vos opérations de gestion sur une seule plateforme, de la communication aux ressources humaines</p>
          <div class="cta-wrap">
            <a href="/contact" class="cta bleu">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-gestion" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-proprietaires-1.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/proprietaires-feature-1.svg" alt="PharmaForms" />
          <p class="text">
            <span>Babillard interne :</span> Conservez une bonne communication avec votre équipe via texto ou courriel grâce au babillard et aux alertes.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/proprietaires-feature-2.svg" alt="PharmaForms" />
          <p class="text"><span>Agenda personnalisable :</span> Attribuez des tâches spécifiques à chacun de vos employés.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/proprietaires-feature-3.svg" alt="PharmaForms" />
          <p class="text">
            <span>Registre des accidents et des erreurs :</span> Conservez un registre numérique pour mieux répondre aux exigences légales de la profession
            et dans l'optique d'amélioration continue.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/proprietaires-feature-4.svg" alt="PharmaForms" />
          <p class="text">
            <span>Module de gestion des ressources humaines :</span> Diminuez le temps de gestion associé aux ressources humaines, dont les suivis de
            formations, des vacances et des congés de maladie.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/proprietaires-feature-5.svg" alt="PharmaForms" />
          <p class="text">
            <span>Dossier employé.e :</span> Consultez facilement les informations clés comme le salaire, les évaluations de performance et plus encore
            dans notre dossier servant de référence principale.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="700">
          <img src="@/assets/img/proprietaires-feature-6.svg" alt="PharmaForms" />
          <p class="text">
            <span>Accès personnalisés :</span> Gérez les accès et les droits des usagers pour la sécurité des informations ainsi que selon les
            responsabilités dans l'équipe.
          </p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Module clinique</h2>
          <h3 class="title medium">Des outils pour tous les besoins des Pharmaciens Cliniciens</h3>
          <p class="text">
            Permettez à vos pharmaciens d’effectuer des opinions, des actes des lois 31/41, des prescriptions de mvl en harmonie avec le flot de travail de
            votre pharmacie
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta bleu">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-gestion" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-proprietaires-2.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/proprietaires-feature-7.svg" alt="PharmaForms" />
          <p class="text">Optimisez votre temps en servant plus de patients, tout en conservant la même qualité d'intervention.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/proprietaires-feature-8.svg" alt="PharmaForms" />
          <p class="text">Atteignez une productivité globale supérieure dans la chaîne de traitement des actes cliniques.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/proprietaires-feature-9.svg" alt="PharmaForms" />
          <p class="text">
            Éliminez les tâches manuelles du processus clinique afin de développer une meilleure relation avec vos patients lors de l'anamnèse.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/proprietaires-feature-10.svg" alt="PharmaForms" />
          <p class="text">Assurez-vous que tous les actes cliniques soient facturés afin d'augmenter les revenus de votre pharmacie.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/proprietaires-feature-11.svg" alt="PharmaForms" />
          <p class="text">Transigez facilement des informations entre RX Toolbox et les autres outils informatiques utilisés dans votre pharmacie.</p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">Module de résidence</h2>
          <h3 class="title medium">Un lien direct et une relation harmonieuse avec les RPA</h3>
          <p class="text">
            Améliorez le quotidien de vos partenaires de soins et devenez proactifs dans la prestation de soins pharmaceutiques à vos patients en résidence
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta bleu">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-gestion" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-proprietaires-3.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/proprietaires-feature-12.svg" alt="PharmaForms" />
          <p class="text">
            <span>Système de commandes :</span> Donnez un accès gratuit à une RPA pour recevoir les commandes à travers la plateforme et les traiter plus
            efficacement.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/proprietaires-feature-13.svg" alt="PharmaForms" />
          <p class="text">
            <span>PharmaConsult :</span> Recevez électroniquement un formulaire rempli par le personnel soignant avec toutes les informations clés pour une
            demande de consultation afin de prendre le patient en charge plus rapidement.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/proprietaires-feature-14.svg" alt="PharmaForms" />
          <p class="text">
            <span>Module de visites médicales :</span> Facilitez votre processus de facturation en produisant un document détaillé des actes cliniques
            effectués lors de vos visites en RPA.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/proprietaires-feature-15.svg" alt="PharmaForms" />
          <p class="text">
            <span>Babillard :</span> Profitez d'un canal de communication direct et sécurisé entre votre pharmacie et la RPA pour répondre en continu à
            leurs demandes et à leurs questions, et ce, dans les plus brefs délais.
          </p>
        </div>
      </div>
    </section>

    <!-- <section class="video-wrap" data-inview="fadeInUp" data-delay="200">
      <div class="browser-top">
        <div class="round"></div>
        <div class="round"></div>
        <div class="round"></div>
      </div>
      <a href="https://www.youtube.com/watch?v=W1k8wqZUSOE" class="video glightbox">
        <div class="data">
          <h3 class="title small">Découvrez RX Tool Box<br>pour les pharmaciens propriétaires</h3>
          <img src="@/assets/img/play.svg" class="play" alt="Play icon">
        </div>
        <div class="overlay"></div>
        <img src="@/assets/img/video-bg.jpg" class="video-bg" alt="Découvrez RX Tool Box pour les Pharmaciens salariés">
      </a>
    </section> -->

    <section class="testimonials-wrap">
      <div class="testimonial-wrap bleu" data-inview="fadeInUp" data-delay="300">
        <div class="line"></div>
        <p class="title">
          « Enfin, un outil qui me permet de <span>diminuer grandement les appels téléphoniques</span> longs et parfois compliqués lors des commandes de
          produits des RPA »
        </p>
        <div class="name">
          <p class="title">Samuel,</p>
          <p class="text">Pharmacien propriétaire</p>
        </div>
        <img src="@/assets/img/testimonials-proprietaire.svg" class="forme" alt="forme" />
      </div>
      <div class="testimonial-wrap bleu" data-inview="fadeInUp" data-delay="200">
        <div class="line"></div>
        <p class="title">« Mes ATP ont enfin des agendas spécifiques <span>à leurs besoins !</span> »</p>
        <div class="name">
          <p class="title">Stéphanie,</p>
          <p class="text">Pharmacienne propriétaire</p>
        </div>
        <img src="@/assets/img/testimonials-proprietaire.svg" class="forme" alt="forme" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Pharmaciens propriétaires'
      },
      meta: [
        {
          name: 'description',
          content: 'Assurez-vous d’une meilleure productivité pour votre équipe et une meilleure rentabilité',
          id: 'desc'
        },
        { name: 'application-name', content: 'Pharmaciens propriétaires' },
        { name: 'twitter:title', content: 'Assurez-vous d’une meilleure productivité pour votre équipe et une meilleure rentabilité' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Assurez-vous d’une meilleure productivité pour votre équipe et une meilleure rentabilité'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Pharmaciens propriétaires' },
        { property: 'og:site_name', content: 'Pharmaciens propriétaires' },
        {
          property: 'og:description',
          content: 'Assurez-vous d’une meilleure productivité pour votre équipe et une meilleure rentabilité'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
