<template>
  <div class="page-wrapper salaries">
    <section class="hero-user">
      <div class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="text">Pharmaciens salariés</h1>
        <h2 class="title"><span>Optimisez votre temps</span> et prenez en charge vos patients de façon plus <span>efficace</span></h2>
        <a v-scroll-to:-120 href="#features" class="cta">
          <p class="text">Découvrez les fonctionnalités</p>
        </a>
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
      </div>
    </section>

    <section class="intro-arguments">
      <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
        RX Toolbox permet de vous concentrer sur votre mission première: <span class="color">offrir un service de qualité à vos clients</span>
      </h2>
      <div class="arguments">
        <div class="argument" data-inview="fadeInUp" data-delay="200">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Documenter vos interventions rapidement et efficacement</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="300">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Faites votre travail clinique et déléguer les tâches techniques à vos ATP</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="400">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Application infonuagique disponible sur toutes les plateformes</p>
        </div>
        <div class="argument" data-inview="fadeInUp" data-delay="500">
          <svg class="check" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31.5 59.7188C15.9338 59.7188 3.28125 47.0662 3.28125 31.5C3.28125 15.9338 15.9338 3.28125 31.5 3.28125C47.0662 3.28125 59.7188 15.9338 59.7188 31.5C59.7188 47.0662 47.0662 59.7188 31.5 59.7188ZM31.5 7.21875C18.1125 7.21875 7.21875 18.1125 7.21875 31.5C7.21875 44.8875 18.1125 55.7812 31.5 55.7812C44.8875 55.7812 55.7812 44.8875 55.7812 31.5C55.7812 18.1125 44.8875 7.21875 31.5 7.21875Z"
            />
            <path
              d="M27.7723 40.8975C27.2473 40.8975 26.7485 40.6875 26.381 40.32L18.9523 32.8912C18.191 32.13 18.191 30.87 18.9523 30.1087C19.7135 29.3475 20.9735 29.3475 21.7348 30.1087L27.7723 36.1462L41.2648 22.6537C42.026 21.8925 43.286 21.8925 44.0473 22.6537C44.8085 23.415 44.8085 24.6749 44.0473 25.4362L29.1635 40.32C28.796 40.6875 28.2973 40.8975 27.7723 40.8975Z"
            />
          </svg>
          <p class="text">Un outil permettant de rapidement rédiger des opinions, les actes des lois 31/41 ainsi que de prescrire les MVL</p>
        </div>
      </div>
    </section>

    <section id="features" class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">ConsultaForms</h2>
          <h3 class="title medium">Obtenez les informations de vos patients de manière efficace lors de vos consultations</h3>
          <p class="text">
            Ayez accès à plus de 150 formulaires standardisés que vous pourrez transmettre à votre patient en toute sécurité. Créez et personnalisez vos
            propres formulaires que vous pourrez utiliser de nouveau !
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta vert">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-clinique" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-salaries-1-2.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/salaries-feature-1.svg" alt="PharmaForms" />
          <p class="text">Prenez en charge plus rapidement de nouveaux patients et diminuez le temps d'attente.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/salaries-feature-2.svg" alt="PharmaForms" />
          <p class="text">Optimisez votre temps en déléguant les tâches techniques à vos ATP et en faisant participer votre patient à l’anamnèse</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/salaries-feature-3.svg" alt="PharmaForms" />
          <p class="text">
            Obtenez l'anamnèse du patient de façon proactive pour arriver à une prescription plus rapidement en ayant toutes les informations importantes
            en main.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="500">
          <img src="@/assets/img/salaries-feature-4.svg" alt="PharmaForms" />
          <p class="text">Facilitez le repérage des drapeaux jaunes et rouges associés à un problème de santé grâce aux questions clés du formulaire.</p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="600">
          <img src="@/assets/img/salaries-feature-5.svg" alt="PharmaForms" />
          <p class="text">Uniformiser vos notes cliniques dans un format facilement transférable à vos dossiers.</p>
        </div>
      </div>
    </section>

    <section class="module-wrap">
      <div class="intro">
        <div class="data" data-inview="slideRight" data-delay="200">
          <h2 class="title small">PharmaNotes</h2>
          <h3 class="title medium">Profitez d'une entrée d'information facilitée, du traitement clinique à la facturation</h3>
          <p class="text">
            Ayez accès à plus de 150 gabarits standardisés de notes que vous pourrez modifier et personnaliser selon votre pratique clinique. Vous pouvez
            créer vos propres gabarits de notes cliniques et les réutiliser !
          </p>
          <div class="cta-wrap">
            <a href="/contact" class="cta vert">
              <p class="text">Demande d’info</p>
            </a>
            <!-- <a href="/module-clinique" class="cta outline">
              <p class="text">En savoir plus</p>
            </a> -->
          </div>
        </div>
        <div class="img" data-inview="slideLeft" data-delay="200">
          <div class="browser-top">
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
          </div>
          <img src="@/assets/img/details-salaries-2.jpg" alt="RX Tool Box - PharmaForms" />
        </div>
      </div>
      <div class="features">
        <div class="feature" data-inview="fadeInUp" data-delay="200">
          <img src="@/assets/img/salaries-feature-6.svg" alt="PharmaForms" />
          <p class="text">
            Générez une note clinique comprenant l'ordonnance et les codes de facturation appropriés pour les actes cliniques exécutés, évitant ainsi les
            oublis et les erreurs de facturation.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="300">
          <img src="@/assets/img/salaries-feature-7.svg" alt="PharmaForms" />
          <p class="text">
            À même RX Toolbox, assurez un suivi électronique ou par télécopieur à un prescripteur lorsqu'il y a un changement dans la prescription de
            médicaments indisponibles.
          </p>
        </div>
        <div class="feature" data-inview="fadeInUp" data-delay="400">
          <img src="@/assets/img/salaries-feature-8.svg" alt="PharmaForms" />
          <p class="text">
            Transigez rapidement l'information entre différents outils utilisés en pharmacie, comme les dossiers patients, ou générez une version PDF
            lorsque nécessaire.
          </p>
        </div>
      </div>
    </section>

    <!-- <section class="video-wrap" data-inview="fadeInUp" data-delay="200">
      <div class="browser-top">
        <div class="round"></div>
        <div class="round"></div>
        <div class="round"></div>
      </div>
      <a href="https://www.youtube.com/watch?v=W1k8wqZUSOE" class="video glightbox">
        <div class="data">
          <h3 class="title small">Découvrez RX Tool Box<br>pour les Pharmaciens salariés</h3>
          <img src="@/assets/img/play.svg" class="play" alt="Play icon">
        </div>
        <div class="overlay"></div>
        <img src="@/assets/img/video-bg.jpg" class="video-bg" alt="Découvrez RX Tool Box pour les Pharmaciens salariés">
      </a>
    </section> -->

    <section class="testimonials-wrap">
      <div class="testimonial-wrap vert" data-inview="fadeInUp" data-delay="200">
        <div class="line"></div>
        <p class="title">« Enfin, un outil me permettant de <span>créer mes propres gabarits</span> de notes standardisées. »</p>
        <div class="name">
          <p class="title">Michelle,</p>
          <p class="text">Pharmacienne salariée</p>
        </div>
        <img src="@/assets/img/testimonials-salarie.svg" class="forme" alt="forme" />
      </div>
      <div class="testimonial-wrap vert" data-inview="fadeInUp" data-delay="300">
        <div class="line"></div>
        <p class="title">
          « Ce que j’aime avec Toolbox, c’est que mon patient participe activement à l’anamnèse et <span>je peux déléguer</span> la saisie de données et la
          facturation de l'acte à mes ATP. »
        </p>
        <div class="name">
          <p class="title">Dominique,</p>
          <p class="text">Pharmacienne salariée</p>
        </div>
        <img src="@/assets/img/testimonials-salarie.svg" class="forme" alt="forme" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Pharmaciens salariés'
      },
      meta: [
        {
          name: 'description',
          content: 'Optimisez votre temps et prenez en charge vos patients de façon plus efficace',
          id: 'desc'
        },
        { name: 'application-name', content: 'Pharmaciens salariés' },
        { name: 'twitter:title', content: 'Optimisez votre temps et prenez en charge vos patients de façon plus efficace' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Optimisez votre temps et prenez en charge vos patients de façon plus efficace'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Pharmaciens salariés' },
        { property: 'og:site_name', content: 'Pharmaciens salariés' },
        {
          property: 'og:description',
          content: 'Optimisez votre temps et prenez en charge vos patients de façon plus efficace'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
