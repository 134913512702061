<template>
  <div class="page-wrapper">
    <section class="hero-tarifs">
      <div class="data" data-inview="fadeInUp" data-delay="200">
        <p class="text">Tarifs</p>
        <h1 class="title">Des forfaits selon <span>vos besoins</span></h1>
      </div>
    </section>

    <section class="price-box salaries">
      <div class="product">
        <h3 class="text">Pharmaciens salariés</h3>
        <h2 class="title medium"><span>Optimisez votre temps</span> et prenez en charge vos patients de façon plus <span>efficace</span></h2>
        <svg class="icon" viewBox="0 0 125 124" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.4203 0.901123C97.4203 0.989927 97.4203 1.08761 97.4203 1.17642C97.4333 5.96885 96.5306 10.7195 94.7607 15.172C93.1051 19.4587 90.6297 23.3803 87.4735 26.7165C84.3216 30.0124 80.5493 32.6503 76.3742 34.478C71.9945 36.392 67.2606 37.3573 62.4824 37.3109C57.5859 37.3605 52.7332 36.3834 48.236 34.4425C44.0065 32.6072 40.1758 29.9616 36.9595 26.6544C33.7498 23.3496 31.2249 19.4411 29.5304 15.1542C27.7437 10.7126 26.8373 5.96501 26.862 1.17642C26.862 1.08761 26.862 0.989927 26.862 0.901123H0.000338276C0.000338276 0.989927 0.000338276 1.08761 0.000338276 1.17642C-0.0183055 6.64528 0.733793 12.0894 2.23437 17.3477C3.68371 22.4279 5.81297 27.2882 8.56414 31.7961C11.3027 36.2741 14.5973 40.386 18.3691 44.0334C22.1626 47.7263 26.4179 50.9113 31.0286 53.5088C35.7227 56.1483 40.7254 58.1946 45.9222 59.6007C51.3353 61.0652 56.9198 61.797 62.5268 61.7764C68.1188 61.7906 73.6878 61.0589 79.087 59.6007C84.284 58.2035 89.2849 56.1567 93.9717 53.5088C98.5599 50.9016 102.796 47.7173 106.578 44.0334C110.35 40.386 113.644 36.2741 116.383 31.7961C119.131 27.2863 121.26 22.4265 122.713 17.3477C124.231 12.092 125.001 6.64781 125 1.17642C125 1.08761 125 0.989927 125 0.901123H97.4203Z"
          />
          <path
            d="M34.9825 96.8097C34.9825 82.086 47.2963 70.1685 62.4647 70.1685C77.6331 70.1685 89.9469 82.1126 89.9469 96.8097C89.9469 111.507 77.6331 123.451 62.4647 123.451C47.2963 123.451 34.9825 111.507 34.9825 96.8097Z"
          />
        </svg>
      </div>
      <div class="data">
        <div class="toggle-wrap">
          <div class="toggle" :class="{ actif: !salarie }" @click.prevent="toggleSalarieMensuel">
            <p class="text">Mensuel</p>
          </div>
          <div class="toggle" :class="{ actif: salarie }" @click.prevent="toggleSalarieAnnuel">
            <p class="text">Annuel</p>
          </div>
        </div>
        <p class="text">Profitez pleinement des fonctionnalités <span>ConsultaForms</span> et <span>PharmaNotes</span>.</p>
        <div class="price-wrap" :class="{ actif: !salarie }">
          <p class="price">19.95</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par mois</p>
          </div>
        </div>
        <div class="price-wrap" :class="{ actif: salarie }">
          <p class="price">199</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par année</p>
          </div>
        </div>
        <div class="cta-wrap">
          <a href="/contact" class="cta"><p class="text">Demande d’info</p></a>
          <p class="text">Taxes en sus</p>
        </div>
      </div>
    </section>

    <section class="price-box rpa">
      <div class="product">
        <h3 class="text">Personnel des RPA</h3>
        <h2 class="title medium">Facilitez l'ensemble de vos <span>opérations</span> quotidiennes et vos <span>communications</span></h2>
        <svg class="icon" viewBox="0 0 125 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.4509 26.9075C97.4509 27.0076 97.4509 27.0993 97.4509 27.1994C97.4783 32.1305 96.5874 37.0235 94.8237 41.6285C93.1881 46.0114 90.7277 50.0402 87.5758 53.497C84.4418 56.8723 80.6683 59.5912 76.4745 61.4956C72.0937 63.4838 67.3316 64.4914 62.5209 64.4481C57.6155 64.4971 52.757 63.4902 48.2753 61.4956C44.0224 59.5965 40.1851 56.8786 36.9822 53.497C33.7606 50.0589 31.2368 46.0282 29.5508 41.6285C27.7608 37.0297 26.8553 32.1342 26.8818 27.1994C26.8818 27.0993 26.8818 27.0076 26.8818 26.9075H0.000351254C0.000351254 27.0076 0.000351254 27.0993 0.000351254 27.1994C-0.0371075 38.304 2.92211 49.2131 8.56606 58.7766C11.2853 63.379 14.5722 67.6214 18.3495 71.4041C22.1375 75.1951 26.4018 78.4781 31.0354 81.1709C35.716 83.8863 40.7211 85.9993 45.9316 87.4596C56.7903 90.4399 68.2514 90.4399 79.1101 87.4596C84.3255 86.0134 89.3323 83.8996 94.0063 81.1709C98.6124 78.4673 102.853 75.1851 106.625 71.4041C110.409 67.6207 113.704 63.3785 116.434 58.7766C122.078 49.2131 125.037 38.304 125 27.1994C125 27.0993 125 27.0076 125 26.9075H97.4509Z"
          />
          <path
            d="M35.2473 27.5237C35.2473 22.08 36.8616 16.7586 39.8859 12.2324C42.9102 7.70612 47.2089 4.17833 52.2382 2.09513C57.2675 0.0119256 62.8016 -0.533136 68.1406 0.528873C73.4797 1.59088 78.384 4.21226 82.2332 8.06152C86.0825 11.9108 88.7039 16.815 89.7659 22.1541C90.8279 27.4932 90.2828 33.0273 88.1996 38.0566C86.1164 43.0859 82.5886 47.3845 78.0624 50.4088C73.5361 53.4332 68.2147 55.0474 62.771 55.0474C55.4713 55.0474 48.4705 52.1476 43.3088 46.9859C38.1471 41.8242 35.2473 34.8235 35.2473 27.5237Z"
          />
        </svg>
      </div>
      <div class="data">
        <div class="residents">
          <p class="text">Nombre de résidents :</p>
          <div class="numbers">
            <div class="number" :class="{ actif: resident1 }" @click.prevent="toggleResident1">
              <p class="text">1 à 10</p>
            </div>
            <div class="number" :class="{ actif: resident2 }" @click.prevent="toggleResident2">
              <p class="text">12 à 29</p>
            </div>
            <div class="number" :class="{ actif: resident3 }" @click.prevent="toggleResident3">
              <p class="text">30 et +</p>
            </div>
          </div>
        </div>

        <div class="toggle-wrap">
          <div class="toggle" :class="{ actif: !rpa }" @click.prevent="toggleRpaMensuel">
            <p class="text">Mensuel</p>
          </div>
          <div class="toggle" :class="{ actif: rpa }" @click.prevent="toggleRpaAnnuel">
            <p class="text">Annuel</p>
          </div>
        </div>

        <p class="text">
          Profitez pleinement des fonctionnalités <span>Système de commandes</span>, <span>PharmaConsult</span>, <span>Visite médicale</span>,
          <span>Dossiers patients</span> et <span>Signes vitaux</span>
        </p>

        <div class="price-wrap" :class="{ actif: !rpa && resident1 }">
          <p class="price">19.95</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par mois</p>
          </div>
        </div>

        <div class="price-wrap" :class="{ actif: !rpa && resident2 }">
          <p class="price">24.95</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par mois</p>
          </div>
        </div>

        <div class="price-wrap" :class="{ actif: !rpa && resident3 }">
          <p class="price">29.95</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par mois</p>
          </div>
        </div>

        <div class="price-wrap" :class="{ actif: rpa && resident1 }">
          <p class="price">199</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par année</p>
          </div>
        </div>

        <div class="price-wrap" :class="{ actif: rpa && resident2 }">
          <p class="price">249</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par année</p>
          </div>
        </div>

        <div class="price-wrap" :class="{ actif: rpa && resident3 }">
          <p class="price">299</p>
          <div class="signs">
            <p class="text">$</p>
            <p class="text">Par année</p>
          </div>
        </div>

        <div class="cta-wrap">
          <a href="/contact" class="cta"><p class="text">Demande d’info</p></a>
          <p class="text">Taxes en sus</p>
        </div>
      </div>
    </section>

    <section class="price-box propio">
      <div class="product">
        <h3 class="text">Pharmaciens propriétaires</h3>
        <h2 class="title medium"><span>Simplifiez et centralisez</span> la gestion de vos opérations en pharmacie</h2>
        <svg class="icon" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.996 61.9542C34.996 56.5144 36.6091 51.1968 39.6312 46.6738C42.6534 42.1508 46.949 38.6255 51.9747 36.5438C57.0004 34.4621 62.5305 33.9174 67.8658 34.9787C73.201 36.0399 78.1018 38.6594 81.9483 42.5059C85.7948 46.3525 88.4143 51.2532 89.4755 56.5885C90.5368 61.9237 89.9921 67.4539 87.9104 72.4796C85.8287 77.5053 82.3034 81.8008 77.7804 84.823C73.2574 87.8452 67.9398 89.4582 62.5 89.4582C58.8878 89.4593 55.3108 88.7487 51.9734 87.3669C48.6359 85.9851 45.6035 83.9592 43.0493 81.405C40.4951 78.8508 38.4692 75.8183 37.0873 72.4809C35.7055 69.1434 34.9949 65.5664 34.996 61.9542Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M38.5823 4.75658C50.0027 0.0261025 62.5693 -1.2116 74.6931 1.19997C86.8169 3.61155 97.9534 9.56409 106.694 18.3049C115.435 27.0457 121.387 38.1821 123.799 50.3059C126.211 62.4297 124.973 74.9964 120.242 86.4167C115.512 97.8371 107.501 107.598 97.2231 114.466C86.9451 121.333 74.8613 124.999 62.5 124.999V112.429L62.4852 124.999C45.9191 124.98 30.037 118.39 18.323 106.676C6.60892 94.962 0.0194382 79.0799 8.79124e-06 62.5138L0 62.5064V62.499C0 50.1377 3.66556 38.054 10.5331 27.7759C17.4007 17.4978 27.1619 9.48705 38.5823 4.75658ZM62.5088 99.8582C69.8947 99.8565 77.1144 97.6655 83.2556 93.5621C89.3993 89.457 94.1878 83.6223 97.0154 76.7958C99.843 69.9693 100.583 62.4576 99.1413 55.2106C97.6998 47.9636 94.1417 41.3069 88.9169 36.0821C83.6922 30.8573 77.0354 27.2992 69.7884 25.8577C62.5414 24.4162 55.0297 25.156 48.2032 27.9836C41.3767 30.8113 35.542 35.5997 31.4369 41.7434C27.3332 47.8851 25.1422 55.1053 25.1408 62.4918C25.1544 72.3977 29.0955 81.8941 36.1002 88.8988C43.1052 95.9039 52.6023 99.8451 62.5088 99.8582Z"
          />
        </svg>
      </div>
      <div class="data">
        <div class="toggle-wrap">
          <div class="toggle" :class="{ actif: !proprio }" @click.prevent="toggleProprioMensuel">
            <p class="text">Mensuel</p>
          </div>
          <div class="toggle" :class="{ actif: proprio }" @click.prevent="toggleProprioAnnuel">
            <p class="text">Annuel</p>
          </div>
        </div>

        <div class="proprio-prices" :class="{ actif: !proprio }">
          <div class="price-wrap">
            <p class="text bold">Gestions des opérations</p>
            <p class="price">19.95</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par mois</p>
            </div>
          </div>
          <div class="price-wrap">
            <p class="text bold">Applications pour résidences</p>
            <p class="price">29.95</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par mois</p>
            </div>
          </div>
          <div class="price-wrap">
            <p class="text bold">Applications cliniques</p>
            <p class="price">39.95</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par mois</p>
            </div>
          </div>
          <div class="price-wrap promo">
            <p class="text bold">Économisez <span>20$/mois</span> avec l’abonnement complet</p>
            <p class="price">69.95</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par mois</p>
            </div>
          </div>
        </div>

        <div class="proprio-prices" :class="{ actif: proprio }">
          <div class="price-wrap">
            <p class="text bold">Gestions des opérations</p>
            <p class="price">199</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par année</p>
            </div>
          </div>
          <div class="price-wrap">
            <p class="text bold">Applications pour résidences</p>
            <p class="price">299</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par année</p>
            </div>
          </div>
          <div class="price-wrap">
            <p class="text bold">Applications cliniques</p>
            <p class="price">449</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par année</p>
            </div>
          </div>
          <div class="price-wrap promo">
            <p class="text bold">Économisez <span>148$/année</span> avec l’abonnement complet</p>
            <p class="price">799</p>
            <div class="signs">
              <p class="text">$</p>
              <p class="text">Par année</p>
            </div>
          </div>
        </div>

        <div class="cta-wrap">
          <a href="/contact" class="cta"><p class="text">Demande d’info</p></a>
          <p class="text">Taxes en sus</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Tarifs'
      },
      meta: [
        {
          name: 'description',
          content: 'Simplifiez le quotidien des pharmaciens salariés et propriétaires ainsi que du personnel soignant des RPA',
          id: 'desc'
        },
        { name: 'application-name', content: 'Tarifs' },
        { name: 'twitter:title', content: 'Simplifiez le quotidien des pharmaciens salariés et propriétaires ainsi que du personnel soignant des RPA' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Simplifiez le quotidien des pharmaciens salariés et propriétaires ainsi que du personnel soignant des RPA'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Tarifs' },
        { property: 'og:site_name', content: 'Tarifs' },
        {
          property: 'og:description',
          content: 'Simplifiez le quotidien des pharmaciens salariés et propriétaires ainsi que du personnel soignant des RPA'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  data() {
    return {
      salarie: false,
      rpa: false,
      proprio: false,
      resident1: true,
      resident2: false,
      resident3: false
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  methods: {
    toggleSalarieMensuel() {
      this.salarie = false
    },
    toggleSalarieAnnuel() {
      this.salarie = true
    },
    toggleRpaMensuel() {
      this.rpa = false
    },
    toggleRpaAnnuel() {
      this.rpa = true
    },
    toggleProprioMensuel() {
      this.proprio = false
    },
    toggleProprioAnnuel() {
      this.proprio = true
    },
    toggleResident1() {
      this.resident1 = true
      this.resident2 = false
      this.resident3 = false
    },
    toggleResident2() {
      this.resident1 = false
      this.resident2 = true
      this.resident3 = false
    },
    toggleResident3() {
      this.resident1 = false
      this.resident2 = false
      this.resident3 = true
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
