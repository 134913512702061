import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import PharmacienSalaries from '@/views/PharmacienSalaries'
import PersonnelRpa from '@/views/PersonnelRpa'
import PharmacienProprietaires from '@/views/PharmacienProprietaires'
import Contact from '@/views/Contact'
import Apropos from '@/views/Apropos'
import Moduleclinique from '@/views/Moduleclinique'
import Moduleresidence from '@/views/Moduleresidence'
import Modulegestion from '@/views/Modulegestion'
import Tarifs from '@/views/Tarifs'
import Promotion from '@/views/Promotion'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/pharmacien-salaries',
      name: 'pharmaciensalaries',
      component: PharmacienSalaries,
      meta: {
        navTheme: 'light',
        footer: 'p1',
      }
    },
    {
      path: '/personnel-rpa',
      name: 'personnelrpa',
      component: PersonnelRpa,
      meta: {
        navTheme: 'light',
        footer: 'p2',
      }
    },
    {
      path: '/pharmacien-proprietaires',
      name: 'pharmacienproprietaires',
      component: PharmacienProprietaires,
      meta: {
        navTheme: 'light',
        footer: 'p3',
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/success',
      name: 'contact',
      component: Contact,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/a-propos',
      name: 'apropos',
      component: Apropos,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/tarifs',
      name: 'tarifs',
      component: Tarifs,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/module-clinique',
      name: 'moduleclinique',
      component: Moduleclinique,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/module-residence',
      name: 'moduleresidence',
      component: Moduleresidence,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/module-gestion',
      name: 'modulegestion',
      component: Modulegestion,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    {
      path: '/essai-gratuit-30-jours',
      name: 'promotion',
      component: Promotion,
      meta: {
        navTheme: '',
        footer: 'default',
      }
    },
    { path: '*', redirect: '/' }
  ]
})

export default router
