<template>
  <div class="page-wrapper">
    <section class="hero">
      <div class="left" data-inview="fadeInUp" data-delay="200">
        <h1 class="title">
          La <span>plateforme</span> pour consacrer plus de temps à vos <span>patients</span> en maximisant l’efficacité de vos
          <span>tâches</span> quotidiennes.
        </h1>
      </div>
      <div class="right">
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
        <img src="@/assets/img/hero-mask.svg" class="mask" alt="Mask" />
        <div class="img" data-inview="revealRight" data-delay="200"></div>
      </div>
    </section>

    <section class="users-wrap">
      <a href="/pharmacien-salaries" class="user">
        <div class="txt_wrap">
          <p class="text">Pharmaciens salariés</p>
          <img src="@/assets/img/arrow-right-blanc.svg" class="arrow" alt="Arrow" />
        </div>
        <div class="gradient"></div>
        <img src="@/assets/img/pharmaciens-salaries-hero-new.jpg" class="user-img" alt="Pharmaciens salariés" />
      </a>
      <a href="/personnel-rpa" class="user">
        <div class="txt_wrap">
          <p class="text">Personnel des RPA</p>
          <img src="@/assets/img/arrow-right-blanc.svg" class="arrow" alt="Arrow" />
        </div>
        <div class="gradient"></div>
        <img src="@/assets/img/personnel-des-rpa-hero-2.jpg" class="user-img" alt="Personnel des RPA" />
      </a>
      <a href="/pharmacien-proprietaires" class="user">
        <div class="txt_wrap">
          <p class="text">Pharmaciens propriétaires</p>
          <img src="@/assets/img/arrow-right-blanc.svg" class="arrow" alt="Arrow" />
        </div>
        <div class="gradient"></div>
        <img src="@/assets/img/pharmaciens-proprietaires-hero.jpg" class="user-img" alt="Pharmaciens propriétaires" />
      </a>
    </section>

    <section class="graph" data-inview="fadeInDown" data-delay="200">
      <img src="@/assets/img/graph.svg" alt="Graph" />
    </section>

    <section class="user-group-wrap">
      <h2 class="title main-title" data-inview="fadeInUp" data-delay="200">
        <span>RX Toolbox facilite et optimise</span> le travail respectif des pharmaciens salariés, du personnel des RPA ainsi que des Pharmaciens
        Propriétaires.
      </h2>

      <div id="salaries" class="user-group">
        <div class="data" data-inview="slideRight" data-delay="200">
          <img src="@/assets/img/salarie-icon.svg" class="icon" alt="Arrow" />
          <h3 class="title small">Pharmaciens salariés</h3>
          <p class="text">
            Grâce à cette nouvelle plateforme, vous pouvez prendre en charge vos patients avec facilité et faire des actes cliniques en recueillant les
            informations de façon beaucoup plus efficace. Optimisez votre temps pour vous concentrer sur votre première mission : offrir un service de
            qualité à vos clients.
          </p>
          <a href="/pharmacien-salaries" class="cta vert"><p class="text">En savoir plus</p></a>
        </div>
        <div class="testimonial-wrap vert" data-inview="slideLeft" data-delay="200">
          <div class="line"></div>
          <p class="title">« Enfin, un outil me permettant de <span>créer mes propres gabarits</span> de notes standardisées. »</p>
          <div class="name">
            <p class="title">Michelle,</p>
            <p class="text">Pharmacienne salariée</p>
          </div>
          <img src="@/assets/img/testimonials-salarie.svg" class="forme" alt="forme" />
        </div>
      </div>

      <div id="rpa" class="user-group">
        <div class="data" data-inview="slideRight" data-delay="200">
          <img src="@/assets/img/rpa-icon.svg" class="icon" alt="Arrow" />
          <h3 class="title small">Personnel des RPA</h3>
          <p class="text">
            Facilitez l'ensemble de vos opérations quotidiennes et communiquez avec les pharmacies et autres professionnels pour toutes demandes de
            consultation.
          </p>
          <a href="/personnel-rpa" class="cta orange"><p class="text">En savoir plus</p></a>
        </div>
        <div class="testimonial-wrap orange" data-inview="slideLeft" data-delay="200">
          <div class="line"></div>
          <p class="title">« Enfin, je peux créer mes propres FADM et les imprimer <span>au moment opportun</span> à chaque mois »</p>
          <div class="name">
            <p class="title">Catherine,</p>
            <p class="text">RPA</p>
          </div>
          <img src="@/assets/img/testimonials-rpa.svg" class="forme" alt="forme" />
        </div>
      </div>

      <div id="proprietaires" class="user-group">
        <div class="data" data-inview="slideRight" data-delay="200">
          <img src="@/assets/img/proprietaire-icon.svg" class="icon" alt="Arrow" />
          <h3 class="title small">Pharmaciens propriétaires</h3>
          <p class="text">
            Créez votre plateforme idéale en sélectionnant uniquement les modules dont vous avez besoin. RX Toolbox centralise la gestion de la pharmacie,
            des patients, des employés et des RPA, en un seul endroit, pour rendre votre processus de gestion plus facile.
          </p>
          <a href="/pharmacien-proprietaires" class="cta bleu"><p class="text">En savoir plus</p></a>
        </div>
        <div class="testimonial-wrap bleu" data-inview="slideLeft" data-delay="200">
          <div class="line"></div>
          <p class="title">« Mes ATP ont enfin des agendas spécifiques <span>à leurs besoins !</span> »</p>
          <div class="name">
            <p class="title">Stéphanie,</p>
            <p class="text">Pharmacienne propriétaire</p>
          </div>
          <img src="@/assets/img/testimonials-proprietaire.svg" class="forme" alt="forme" />
        </div>
      </div>
    </section>

    <section class="intro-about" data-inview="fadeInUp" data-delay="200">
      <div class="img">
        <img src="@/assets/img/jean-louis-auger-small-2.jpg" alt="Jean-Louis Auger" />
      </div>
      <div class="data">
        <h2 class="title">
          "Des <span>outils</span> pour les professionnels de la santé <span>développés</span> par des <span>professionnels</span> de la santé&nbsp;!"
        </h2>
        <p class="text">Jean-Louis Auger - Pharmacien et fondateur</p>
        <a href="/a-propos" class="cta dark"><p class="text">En savoir plus sur RX Toolbox</p></a>
      </div>
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </section>

    <section class="prix-opq" data-inview="fadeInUp" data-delay="200">
      <img src="@/assets/img/opq-logo.png" class="opq" alt="Logo Ordre des pharmaciens du Québec" />
      <h3 class="title">RX Toolbox reçoit le <span>prix Innovation 2020</span> par l’Ordre des pharmaciens du Québec</h3>
      <a href="https://www.youtube.com/watch?v=A92XN48XxHI" target="_blank" class="round glightbox">
        <svg viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.5 0C20.836 0 0 20.836 0 46.5C0 72.164 20.836 93 46.5 93C72.164 93 93 72.164 93 46.5C93 20.836 72.164 0 46.5 0ZM38.1068 26.8455C37.1574 26.2294 35.9407 26.1868 34.9448 26.7293C33.945 27.2718 33.325 28.3142 33.325 29.4495V63.5495C33.325 64.6849 33.945 65.7272 34.9448 66.2698C35.9407 66.8123 37.1574 66.7697 38.1068 66.1535L64.4563 49.103C65.3398 48.5295 65.8746 47.5492 65.8746 46.499C65.8746 45.4489 65.3398 44.4685 64.4563 43.895L38.1068 26.8455Z"
            fill="#0B8A81"
          />
        </svg>
      </a>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Accueil'
      },
      meta: [
        {
          name: 'description',
          content: 'La plateforme pour consacrer plus de temps à vos patients en maximisant l’efficacité de vos tâches quotidiennes.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Accueil' },
        {
          name: 'twitter:title',
          content: 'La plateforme pour consacrer plus de temps à vos patients en maximisant l’efficacité de vos tâches quotidiennes.'
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'La plateforme pour consacrer plus de temps à vos patients en maximisant l’efficacité de vos tâches quotidiennes.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Accueil' },
        { property: 'og:site_name', content: 'Accueil' },
        {
          property: 'og:description',
          content: 'La plateforme pour consacrer plus de temps à vos patients en maximisant l’efficacité de vos tâches quotidiennes.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
