<template>
  <div class="page-wrapper">
    <section class="hero modules">
      <div class="left" data-inview="fadeInUp" data-delay="200">
        <p class="text">Module Services aux Résidences</p>
        <h1 class="title">
          Ce module facilite l’intégration de vos activités cliniques et facilite les opérations courantes de votre personnel envers les résidences.
        </h1>
      </div>
      <div class="right">
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
        <img src="@/assets/img/hero-mask.svg" class="mask" alt="Mask" />
        <div class="img residence" data-inview="revealRight" data-delay="200"></div>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Babillard partagé</h2>
        <p class="text">
          Plateforme de communication privilégiée entre le personnel de la pharmacie et le personnel de vos résidences. Pour chaque message posté, il est
          possible de valider si le destinataire en a bien pris connaissance. Un message peut être envoyé à plus d’une résidence.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Commandes</h2>
        <p class="text">
          Pour chaque patient, il est possible pour le personnel de la résidence de commander soit des médicaments ou tout autre article requis. Les
          articles peuvent être laissés au dossier de façon permanente, facilitant le processus de commande récurrente. Chaque commande peut être
          sauvegardée: précision, justesse et traçabilité.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Visite Médicale</h2>
        <p class="text">
          Devenez pro-actif lors des visites médicales planifiées en ayant accès aux problèmes de chaque patient avant la visite médicale ou en ajoutant
          des notes sur vos patients. Chaque visite est automatiquement sauvegardée dans le dossier patient, donnant accès à l’historique.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Pharmaconsult</h2>
        <p class="text">
          Accès à plus de 60 formulaires permettant au personnel soignant de bien documenter un problème de santé que le pharmacien pourrait solutionner.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Santé et notes d’évolution</h2>
        <p class="text">
          Consultez en ligne différents paramètres santé et notes d’évolution de vos patients. Le personnel de la résidence peut noter des informations sur
          la douleur, la migraine, la tension artérielle, la glycémie capillaire ainsi que le poids et la taille. Et bien sûr un accès aux notes
          d’évolution si requis.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Module Services aux Résidences'
      },
      meta: [
        {
          name: 'description',
          content:
            'Le module résidence permet une communication bidirectionnelle entre vous et le personnel de vos résidences. Ce module facilite l’intégration de vos activités cliniques et facilite les opérations courantes de votre personnel envers les résidences.',
          id: 'desc'
        },
        { name: 'application-name', content: 'Module Services aux Résidences' },
        {
          name: 'twitter:title',
          content:
            'Le module résidence permet une communication bidirectionnelle entre vous et le personnel de vos résidences. Ce module facilite l’intégration de vos activités cliniques et facilite les opérations courantes de votre personnel envers les résidences.'
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c:
            'Le module résidence permet une communication bidirectionnelle entre vous et le personnel de vos résidences. Ce module facilite l’intégration de vos activités cliniques et facilite les opérations courantes de votre personnel envers les résidences.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Module Services aux Résidences' },
        { property: 'og:site_name', content: 'Module Services aux Résidences' },
        {
          property: 'og:description',
          content:
            'Le module résidence permet une communication bidirectionnelle entre vous et le personnel de vos résidences. Ce module facilite l’intégration de vos activités cliniques et facilite les opérations courantes de votre personnel envers les résidences.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
