<template>
  <div class="page-wrapper">
    <section class="hero modules">
      <div class="left" data-inview="fadeInUp" data-delay="200">
        <p class="text">Module Services cliniques</p>
        <h1 class="title">
          <span>Simplifier</span> le processus de documentation de vos actes cliniques et <span>harmonie</span> avec le flot de travail de votre pharmacie
        </h1>
      </div>
      <div class="right">
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow" />
        <img src="@/assets/img/hero-mask.svg" class="mask" alt="Mask" />
        <div class="img" data-inview="revealRight" data-delay="200"></div>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">Module de suivi clinique</h2>
        <p class="text">
          Minimiser le temps requis à la planification de vos activités cliniques. Une fois le résultat obtenu et que votre analyse clinique est complétée,
          tout se crée instantanément: le résultat du moment, la demande d’analyse sanguine, l’historique des résultats, la planification à l’agenda ainsi
          que les documents requis pour votre équipe et ceux destinés aux autres professionnels de la santé. Le module comprend les suivis suivants :
        </p>
        <ul>
          <li class="text">Hypertension</li>
          <li class="text">Hypothyroïdie</li>
          <li class="text">Dyslipidémie</li>
          <li class="text">Diabète</li>
          <li class="text">Anticoagulothérapie</li>
          <li class="text">Migraine</li>
          <li class="text">Douleur chronique</li>
          <li class="text">Modèle de suivi personnalisable</li>
        </ul>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>

    <section class="split-img-text center right">
      <div class="img" data-inview="slideRight" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
      <div class="data" data-inview="slideLeft" data-delay="200">
        <h2 class="title medium">Pharma-Notes</h2>
        <p class="text">
          Inspiré des modèles de notes les plus utilisés dans le domaine de la santé, les modèles SOAP et DAP permettent d’intervenir en utilisant un moyen
          de communication reconnu par l’ensemble des professionnels de la santé. En quelques clics, complétez votre intervention et déléguez les tâches
          techniques à votre personnel.
        </p>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
    </section>

    <section class="split-img-text center left">
      <div class="data" data-inview="slideRight" data-delay="200">
        <h2 class="title medium">PharmaForms</h2>
        <p class="text">
          Pharmaforms est composé de formulaires destinés à votre patient selon les moyens technologiques dont il dispose : adresse courriel, tablette,
          téléphone cellulaire ou encore au bon vieux papier. Il permet à la fois d’obtenir de l’information dans le but de prescrire un traitement ou
          encore de documenter vos suivis de la thérapie médicamenteuse.
        </p>
        <ul>
          <li class="text">Possibilité de créer ou personnaliser un gabarit</li>
          <li class="text">Possibilité de joindre le formulaire dans une note pour fins de prescription et de documentation</li>
          <li class="text">Document facilement transférable d’un dossier informatique à un autre</li>
          <li class="text">Transmission sécurisée à votre patient</li>
          <li class="text">Proactivité de votre patient lors de la prestation de soins pharmaceutiques</li>
        </ul>
        <a href="/contact" class="cta dark">
          <p class="text">Demande d'info</p>
        </a>
      </div>
      <div class="img" data-inview="slideLeft" data-delay="200">
        <div class="browser-top">
          <div class="round"></div>
          <div class="round"></div>
          <div class="round"></div>
        </div>
        <img class="no_border" src="@/assets/img/rx-app.jpg" alt="" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Module Services cliniques'
      },
      meta: [
        {
          name: 'description',
          content: 'Simplifier le processus de documentation de vos actes cliniques et harmonie avec le flot de travail de votre pharmacie',
          id: 'desc'
        },
        { name: 'application-name', content: 'Module Services cliniques' },
        {
          name: 'twitter:title',
          content: 'Simplifier le processus de documentation de vos actes cliniques et harmonie avec le flot de travail de votre pharmacie'
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Simplifier le processus de documentation de vos actes cliniques et harmonie avec le flot de travail de votre pharmacie'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Module Services cliniques' },
        { property: 'og:site_name', content: 'Module Services cliniques' },
        {
          property: 'og:description',
          content: 'Simplifier le processus de documentation de vos actes cliniques et harmonie avec le flot de travail de votre pharmacie'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
