// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, addDoc, collection } from 'firebase/firestore'
// import { getStorage, ref, uploadBytes } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB2b_-I_6wadYagGRq97_8Z2RiQ1lNqSfU',
  authDomain: 'jnv-web.firebaseapp.com',
  databaseURL: 'https://jnv-web.firebaseio.com',
  projectId: 'jnv-web',
  storageBucket: 'jnv-web.appspot.com',
  messagingSenderId: '21527426856',
  appId: '1:21527426856:web:6d4afff195d3550261021d',
  measurementId: 'G-67YC6X3Z82',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
// const storage = getStorage()

// Create
const createContact = async function createContact(contact) {
  const res = await addDoc(collection(db, 'rx_contacts'), contact)
  return res.id
}

// Create
const createDemande = async function createDemande(contact) {
  const res = await addDoc(collection(db, 'rx_demandes'), contact)
  return res.id
}

export { createContact, createDemande }
