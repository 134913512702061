<template>
  <div class="page-wrapper">
    <section class="hero-contact">
      <div v-if="!isSuccess" class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="title">Profitez d'un <span>essai gratuit</span><br />de 30 jours</h1>
        <p class="text">
          Remplissez le formulaire ci-joint et vous recevrez votre code promotionnel par courriel. Consacrez plus de temps à vos patients en maximisant
          l'efficacité de vos tâches quotidiennes - inscrivez-vous dès aujourd'hui!
        </p>
      </div>

      <div v-else class="data" data-inview="fadeInUp" data-delay="200">
        <h1 class="title"><span>Merci!</span></h1>
        <p class="text">Votre demande a bien été reçue. Vous recevrez votre code d'activation par courriel.</p>
      </div>
    </section>

    <section v-if="!isSuccess" class="form-wrap" data-inview="fadeInUp" data-delay="400">
      <div class="form">
        <div class="lines">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <h2 class="title small-light">Demandez votre accès</h2>
        <form action="/success" name="soumissions" method="post" enctype="multipart/form-data" @submit="sendForm">
          <input v-model="form.nom" type="text" placeholder="Prénom et nom" required />
          <input v-model="form.courriel" type="mail" placeholder="Courriel" required />
          <select v-model="form.profession">
            <option value="">Profession</option>
            <option value="Pharmaciens salariés">Pharmaciens salariés</option>
            <option value="Pharmaciens propriétaires">Pharmaciens propriétaires</option>
          </select>
          <div class="checkbox-wrap">
            <input v-model="form.autorisation" type="checkbox" name="autorisation" required />
            <p class="text">Je souhaite recevoir mon code pour l'essai gratuit de 30 jours</p>
          </div>
          <input type="submit" value="Envoyer" />
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createDemande } from '@/misc/firebase-init'

// Intro Animation Scripts
require('@/misc/animations-layer')

export default {
  head() {
    return {
      title: {
        inner: 'Essai gratuit 30 jours',
      },
      meta: [
        {
          name: 'description',
          content:
            "Remplissez le formulaire ci-joint et vous recevrez votre code promotionnel par courriel. Consacrez plus de temps à vos patients en maximisant l'efficacité de vos tâches quotidiennes - inscrivez-vous dès aujourd'hui!",
          id: 'desc',
        },
        { name: 'application-name', content: 'Essai gratuit 30 jours' },
        {
          name: 'twitter:title',
          content:
            "Remplissez le formulaire ci-joint et vous recevrez votre code promotionnel par courriel. Consacrez plus de temps à vos patients en maximisant l'efficacité de vos tâches quotidiennes - inscrivez-vous dès aujourd'hui!",
        },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: "Remplissez le formulaire ci-joint et vous recevrez votre code promotionnel par courriel. Consacrez plus de temps à vos patients en maximisant l'efficacité de vos tâches quotidiennes - inscrivez-vous dès aujourd'hui!",
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Essai gratuit 30 jours' },
        { property: 'og:site_name', content: 'Essai gratuit 30 jours' },
        {
          property: 'og:description',
          content:
            "Remplissez le formulaire ci-joint et vous recevrez votre code promotionnel par courriel. Consacrez plus de temps à vos patients en maximisant l'efficacité de vos tâches quotidiennes - inscrivez-vous dès aujourd'hui!",
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://rxtoolbox.com/img/og-rx.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://rxtoolbox.com/img/og-rx.jpg',
        },
      ],
    }
  },
  data() {
    return {
      form: {
        nom: '',
        courriel: '',
        profession: '',
        autorisation: '',
      },
      isSending: false,
      error: '',
      isSuccess: false,
    }
  },
  watch: {
    $route: {
      handler($route) {
        // If route path is success
        if ($route) {
          if ($route.path === '/success') {
            this.isSuccess = true
          }
        }
      },
      immediate: true,
    },
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    async sendForm(event) {
      event.preventDefault()

      console.log('sendForm')

      if (this.isSending) {
        return
      }

      this.isSending = true
      const contact = this.form

      contact.createdAt = new Date().toISOString()

      try {
        await createDemande(contact)
        this.form = {}
        window.location.href = '/success'
      } catch (e) {
        console.log(e)
        this.error = e
        this.isSending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
